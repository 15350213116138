<template>
  <div class="misc-wrapper">
    <div class="misc-inner p-2 p-sm-3">
      <div class="w-100 text-center">
        <h2 class="mb-1">{{ $t('access_denied.title') }} 🔐</h2>
        <p class="mb-2">
          {{ $t('access_denied.message') }}
        </p>
        <div class="mb-2">
          <b-dropdown variant="outline-primary" v-if="user.organizations" left toggle-class="d-flex align-items-center text-dark">
          <template #button-content>
            <b-avatar
              size="40"
              variant="light-secondary"
              :badge="organizationsLength"
              badge-left
              badge-top
              badge-offset="-0.25em"
              badge-variant="info"
            >
              <feather-icon icon="HomeIcon" size="22" />
            </b-avatar>
            <div v-if="$store.getters['app/mdAndUp']" class="organisation-nav">
              <p class="organization-name organization-color font-weight-bolder mb-0 ml-50">
                {{ selectedOrganization.name }}
              </p>
            </div>
          </template>

          <b-dropdown-item
            v-for="organization in user.organizations"
            :key="organization.id"
            @click="setSelectedOrganisation(organization)"
          >
            <span class="text-nowrap">{{ organization.name }}</span>
          </b-dropdown-item>
        </b-dropdown>
        </div>
        <b-button class="mb-1 btn-sm-block" :to="{ name: 'home' }">{{ $t('access_denied.return_home') }}</b-button>
        <b-img fluid :src="imgUrl" />
      </div>
    </div>
  </div>
</template>

<script>
import OrganizationDropdown from "@/layouts/components/app-navbar/components/OrganizationDropdown.vue";
import {mapActions, mapState} from "vuex";
import router from "@/router/router";

export default {
  name: 'NotAuthorized',
  components: {OrganizationDropdown},

  data() {
    return {
      downImg: require('@/assets/images/pages/not-authorized.svg'),
    }
  },
  computed: {
    ...mapState('auth', ['user', 'selectedOrganization']),
    organizationsLength() {
      return this.user.organizations.length.toString()
    },
    imgUrl() {
      if (this.$store.state.appConfig.layout.skin === 'dark') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.downImg = require('@/assets/images/pages/not-authorized-dark.svg')
        return this.downImg
      }
      return this.downImg
    },
  },
  methods: {
    ...mapActions('auth', ['setSelectedOrganization']),
    setSelectedOrganisation(organization) {
      this.$bvModal
        .msgBoxConfirm(this.$t('organization.modal.edit'), {
          title: this.$t('alert.delete_confirmation.title'),
          size: 'sm',
          okVariant: 'primary',
          okTitle: this.$t('common.yes'),
          cancelTitle: this.$t('common.no'),
          cancelVariant: 'outline-secondary',
          hideHeaderClose: false,
          centered: true,
        })
        .then(isConfirmed => {
          if (isConfirmed) {
            this.setSelectedOrganization(organization)

            // Si dans la query le redirect est défini
            if (this.$route.query.redirect) {
              this.$router.replace(router.currentRoute.query.redirect)
            } else if (this.$can('TRIP_ACCOUNTING_VIEW')) {
              this.$router.push({ name: 'OrdersManager', query: { uniqueKey: `${Date.now()}` }})
            } else if (this.$route.name !== 'home') {
              this.$router.push({ name: 'home' })
            }
          }
        })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-misc.scss';
</style>
